import { render, staticRenderFns } from "./MusicPanel.vue?vue&type=template&id=28a62b82&scoped=true"
import script from "./MusicPanel.vue?vue&type=script&lang=js"
export * from "./MusicPanel.vue?vue&type=script&lang=js"
import style0 from "./MusicPanel.vue?vue&type=style&index=0&id=28a62b82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a62b82",
  null
  
)

export default component.exports