import Vue from 'vue'

const eventBus = new Vue()

export default {
  // 显示提示信息方法
  showNotification: function (color, title = 'request success', text, fn) {
    Vue.prototype.$vs.notification({
      duration: 2000,
      progress: 'auto',
      color: color,
      position: 'top-center',
      title: title,
      text: text
    })
    if (fn) {
      setTimeout(fn, 2000)
    }
  },

  eventBus: function () {
    return eventBus
  },

  // 获取用户访问设备的类型
  getDeviceType: function () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // 利用正则表达式检查用户代理字符串中是否存在移动设备特有的标识
    if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent)) {
      return 'mobile'
    } else if (/ipad|ipad air|ipad pro/i.test(userAgent)) {
      return 'pad'
    } else {
      return 'pc'
    }
  },

  // 复制内容到剪贴板
  copyToClipboard: function (text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        this.showNotification('success', 'Operation successful', 'Copy to clipboard successful')
      })
      .catch((error) => {
        this.showNotification('success', 'Operation failed', 'Copy to clipboard failed' + error)
      })
  }
}
