<template>
  <div id="app" class="content">
    <Navbar v-if="!$route.meta.hideHeader"/>
    <router-view />
    <Footer v-if="!$route.meta.hideFooter && footerActive"/>

    <Login />
    <Register />

    <FlexibleBall v-if="!$route.meta.hideFlexibleBall" :this_="this_"></FlexibleBall>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import FlexibleBall from '@/components/FlexibleBall.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Login,
    Register,
    FlexibleBall
  },
  data () {
    return {
      this_: this,
      footerActive: false
    }
  },
  created () {
    this.common.eventBus().$on('showFooter', () => {
      this.footerActive = true
    })
  }
}
</script>

<style>
.w-full {
  width: 100% !important;
}

.w-full .vs-card__text {
  width: 100%;
}
</style>
