<template>
    <div>
        <div class="cat_container">

            <!-- 小猫形象 -->
            <div class="cat animate__animated animate__backInUp">
                <div class="catHead"></div>
                <div class="catEars">
                    <span class="rightEar"></span>
                </div>
                <div class="face">
                    <span class="mouth"></span>
                </div>
                <div id="leftPaw" class="leftPaw">
                    <span id="paws" class="paws">
                        <span id="circles" class="circles"></span>
                    </span>
                </div>
                <div id="rightPaw" class="rightPaw">
                    <span id="rpaws" class="paws">
                        <span id="rcircles" class="circles"></span>
                    </span>
                </div>
            </div>

            <!-- 键盘 -->
            <div class="surface" id="cat_keyboard">
                <div v-for="(item, index) in keyList" :key="index" :id="item.key" :class="[ item.bg ? 'key-item dark' : 'key-item' ]"></div>
            </div>

            <!-- <div>
                <input type="text" @keydown="hello">
                <input type="password" @focus="test" @blur="test2">
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
  name: 'AboutMe',
  data () {
    return {
      keyList: [
        { key: '1', keyCode: 49, bg: true },
        { key: '2', keyCode: 50, bg: true },
        { key: '3', keyCode: 51, bg: true },
        { key: '4', keyCode: 52, bg: true },
        { key: '5', keyCode: 53, bg: true },
        { key: '6', keyCode: 54, bg: true },
        { key: '7', keyCode: 55, bg: true },
        { key: '8', keyCode: 56, bg: true },
        { key: '9', keyCode: 57, bg: true },
        { key: '0', keyCode: 48, bg: true },
        { key: 'Q', keyCode: 81, bg: false },
        { key: 'W', keyCode: 87, bg: false },
        { key: 'E', keyCode: 69, bg: false },
        { key: 'R', keyCode: 82, bg: false },
        { key: 'T', keyCode: 84, bg: false },
        { key: 'Y', keyCode: 89, bg: false },
        { key: 'U', keyCode: 85, bg: false },
        { key: 'I', keyCode: 73, bg: false },
        { key: 'O', keyCode: 79, bg: false },
        { key: 'P', keyCode: 80, bg: false },
        { key: 'A', keyCode: 65, bg: false },
        { key: 'S', keyCode: 83, bg: false },
        { key: 'D', keyCode: 68, bg: false },
        { key: 'F', keyCode: 70, bg: false },
        { key: 'G', keyCode: 71, bg: false },
        { key: 'H', keyCode: 72, bg: true },
        { key: 'J', keyCode: 74, bg: false },
        { key: 'K', keyCode: 75, bg: false },
        { key: 'L', keyCode: 76, bg: true },
        { key: 'test1', keyCode: 0, bg: false },
        { key: 'Z', keyCode: 90, bg: true },
        { key: 'X', keyCode: 88, bg: false },
        { key: 'C', keyCode: 67, bg: false },
        { key: 'V', keyCode: 86, bg: false },
        { key: 'B', keyCode: 66, bg: false },
        { key: 'N', keyCode: 78, bg: false },
        { key: 'M', keyCode: 77, bg: false },
        { key: 'test2', keyCode: 0, bg: false },
        { key: 'test3', keyCode: 0, bg: false },
        { key: 'test3', keyCode: 0, bg: false }
      ]
    }
  },
  methods: {
    mounted () {
    },
    blurPassword () {
      document.getElementById('circles').classList.add('circles')
      document.getElementById('paws').classList.add('paws')
      document.getElementById('leftPaw').classList.remove('eyes_cover_left_hand')

      document.getElementById('rcircles').classList.add('circles')
      document.getElementById('rpaws').classList.add('paws')
      document.getElementById('rightPaw').classList.remove('eyes_cover_right_hand')

      var eyeColor = ''
      var theme = window.localStorage.getItem('vs-theme')
      if (theme === 'dark') {
        eyeColor = '#FFF'
      } else {
        eyeColor = '#000'
      }

      var leftEye = document.getElementsByClassName('face')
      for (var i = 0; i < leftEye.length; i++) {
        leftEye[i].style.backgroundColor = eyeColor
      }
      var style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = `
        .face:before {
          background-color: ` + eyeColor + ` !important;
        }
      `
      document.head.appendChild(style)
    },
    focusPassword () {
      document.getElementById('circles').classList.remove('circles')
      document.getElementById('paws').classList.remove('paws')
      document.getElementById('leftPaw').classList.add('eyes_cover_left_hand')

      document.getElementById('rcircles').classList.remove('circles')
      document.getElementById('rpaws').classList.remove('paws')
      document.getElementById('rightPaw').classList.add('eyes_cover_right_hand')

      var leftEye = document.getElementsByClassName('face')
      for (var i = 0; i < leftEye.length; i++) {
        leftEye[i].style.backgroundColor = 'transparent'
      }
      var style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = `
        .face:before {
          background-color: transparent !important;
        }
      `
      document.head.appendChild(style)
    },
    focusUsername () {
      const keyCode = event.keyCode

      const leftPaw = document.getElementById('leftPaw').classList
      const rightPaw = document.getElementById('rightPaw').classList
      const paws = document.getElementById('paws').classList
      const circles = document.getElementById('circles').classList
      const rpaws = document.getElementById('rpaws').classList
      const rcircles = document.getElementById('rcircles').classList
      leftPaw.toggle('leftPaw')
      rightPaw.toggle('rightPaw')
      paws.toggle('paws')
      circles.toggle('circles')
      rpaws.toggle('paws')
      rcircles.toggle('circles')
      leftPaw.toggle('leftPaw2')
      rightPaw.toggle('rightPaw2')

      const num1 = document.getElementById('1').classList
      const num2 = document.getElementById('2').classList
      const num3 = document.getElementById('3').classList
      const num4 = document.getElementById('4').classList
      const num5 = document.getElementById('5').classList
      const num6 = document.getElementById('6').classList
      const num7 = document.getElementById('7').classList
      const num8 = document.getElementById('8').classList
      const num9 = document.getElementById('9').classList
      const num0 = document.getElementById('0').classList
      const Q = document.getElementById('Q').classList
      const W = document.getElementById('W').classList
      const E = document.getElementById('E').classList
      const R = document.getElementById('R').classList
      const T = document.getElementById('T').classList
      const Y = document.getElementById('Y').classList
      const U = document.getElementById('U').classList
      const I = document.getElementById('I').classList
      const O = document.getElementById('O').classList
      const P = document.getElementById('P').classList
      const A = document.getElementById('A').classList
      const S = document.getElementById('S').classList
      const D = document.getElementById('D').classList
      const F = document.getElementById('F').classList
      const G = document.getElementById('G').classList
      const H = document.getElementById('H').classList
      const J = document.getElementById('J').classList
      const K = document.getElementById('K').classList
      const L = document.getElementById('L').classList
      const Z = document.getElementById('Z').classList
      const X = document.getElementById('X').classList
      const C = document.getElementById('C').classList
      const V = document.getElementById('V').classList
      const B = document.getElementById('B').classList
      const N = document.getElementById('N').classList
      const M = document.getElementById('M').classList

      if (keyCode === 49) {
        num1.toggle('dark')
        num1.toggle('light')
      } else if (keyCode === 50) {
        num2.toggle('dark')
        num2.toggle('light')
      } else if (keyCode === 51) {
        num3.toggle('dark')
        num3.toggle('light')
      } else if (keyCode === 52) {
        num4.toggle('dark')
        num4.toggle('light')
      } else if (keyCode === 53) {
        num5.toggle('dark')
        num5.toggle('light')
      } else if (keyCode === 54) {
        num6.toggle('dark')
        num6.toggle('light')
      } else if (keyCode === 55) {
        num7.toggle('dark')
        num7.toggle('light')
      } else if (keyCode === 56) {
        num8.toggle('dark')
        num8.toggle('light')
      } else if (keyCode === 57) {
        num9.toggle('dark')
        num9.toggle('light')
      } else if (keyCode === 48) {
        num0.toggle('dark')
        num0.toggle('light')
      } else if (keyCode === 81) {
        Q.toggle('dark')
        Q.toggle('light')
      } else if (keyCode === 87) {
        W.toggle('dark')
        W.toggle('light')
      } else if (keyCode === 69) {
        E.toggle('dark')
        E.toggle('light')
      } else if (keyCode === 82) {
        R.toggle('dark')
        R.toggle('light')
      } else if (keyCode === 84) {
        T.toggle('dark')
        T.toggle('light')
      } else if (keyCode === 89) {
        Y.toggle('dark')
        Y.toggle('light')
      } else if (keyCode === 85) {
        U.toggle('dark')
        U.toggle('light')
      } else if (keyCode === 73) {
        I.toggle('dark')
        I.toggle('light')
      } else if (keyCode === 79) {
        O.toggle('dark')
        O.toggle('light')
      } else if (keyCode === 80) {
        P.toggle('dark')
        P.toggle('light')
      } else if (keyCode === 65) {
        A.toggle('dark')
        A.toggle('light')
      } else if (keyCode === 83) {
        S.toggle('dark')
        S.toggle('light')
      } else if (keyCode === 68) {
        D.toggle('dark')
        D.toggle('light')
      } else if (keyCode === 70) {
        F.toggle('dark')
        F.toggle('light')
      } else if (keyCode === 71) {
        G.toggle('dark')
        G.toggle('light')
      } else if (keyCode === 72) {
        H.toggle('dark')
        H.toggle('light')
      } else if (keyCode === 73) {
        I.toggle('dark')
        I.toggle('light')
      } else if (keyCode === 74) {
        J.toggle('dark')
        J.toggle('light')
      } else if (keyCode === 75) {
        K.toggle('dark')
        K.toggle('light')
      } else if (keyCode === 76) {
        L.toggle('dark')
        L.toggle('light')
      } else if (keyCode === 90) {
        Z.toggle('dark')
        Z.toggle('light')
      } else if (keyCode === 88) {
        X.toggle('dark')
        X.toggle('light')
      } else if (keyCode === 67) {
        C.toggle('dark')
        C.toggle('light')
      } else if (keyCode === 86) {
        V.toggle('dark')
        V.toggle('light')
      } else if (keyCode === 66) {
        B.toggle('dark')
        B.toggle('light')
      } else if (keyCode === 78) {
        N.toggle('dark')
        N.toggle('light')
      } else if (keyCode === 77) {
        M.toggle('dark')
        M.toggle('light')
      }

      setTimeout(() => {
        leftPaw.toggle('leftPaw')
        rightPaw.toggle('rightPaw')
        paws.toggle('paws')
        circles.toggle('circles')
        rpaws.toggle('paws')
        rcircles.toggle('circles')
        leftPaw.toggle('leftPaw2')
        rightPaw.toggle('rightPaw2')
        if (keyCode === 49) {
          num1.toggle('dark')
          num1.toggle('light')
        } else if (keyCode === 50) {
          num2.toggle('dark')
          num2.toggle('light')
        } else if (keyCode === 51) {
          num3.toggle('dark')
          num3.toggle('light')
        } else if (keyCode === 52) {
          num4.toggle('dark')
          num4.toggle('light')
        } else if (keyCode === 53) {
          num5.toggle('dark')
          num5.toggle('light')
        } else if (keyCode === 54) {
          num6.toggle('dark')
          num6.toggle('light')
        } else if (keyCode === 55) {
          num7.toggle('dark')
          num7.toggle('light')
        } else if (keyCode === 56) {
          num8.toggle('dark')
          num8.toggle('light')
        } else if (keyCode === 57) {
          num9.toggle('dark')
          num9.toggle('light')
        } else if (keyCode === 48) {
          num0.toggle('dark')
          num0.toggle('light')
        } else if (keyCode === 81) {
          Q.toggle('dark')
          Q.toggle('light')
        } else if (keyCode === 87) {
          W.toggle('dark')
          W.toggle('light')
        } else if (keyCode === 69) {
          E.toggle('dark')
          E.toggle('light')
        } else if (keyCode === 82) {
          R.toggle('dark')
          R.toggle('light')
        } else if (keyCode === 84) {
          T.toggle('dark')
          T.toggle('light')
        } else if (keyCode === 89) {
          Y.toggle('dark')
          Y.toggle('light')
        } else if (keyCode === 85) {
          U.toggle('dark')
          U.toggle('light')
        } else if (keyCode === 73) {
          I.toggle('dark')
          I.toggle('light')
        } else if (keyCode === 79) {
          O.toggle('dark')
          O.toggle('light')
        } else if (keyCode === 80) {
          P.toggle('dark')
          P.toggle('light')
        } else if (keyCode === 65) {
          A.toggle('dark')
          A.toggle('light')
        } else if (keyCode === 83) {
          S.toggle('dark')
          S.toggle('light')
        } else if (keyCode === 68) {
          D.toggle('dark')
          D.toggle('light')
        } else if (keyCode === 70) {
          F.toggle('dark')
          F.toggle('light')
        } else if (keyCode === 71) {
          G.toggle('dark')
          G.toggle('light')
        } else if (keyCode === 72) {
          H.toggle('dark')
          H.toggle('light')
        } else if (keyCode === 73) {
          I.toggle('dark')
          I.toggle('light')
        } else if (keyCode === 74) {
          J.toggle('dark')
          J.toggle('light')
        } else if (keyCode === 75) {
          K.toggle('dark')
          K.toggle('light')
        } else if (keyCode === 76) {
          L.toggle('dark')
          L.toggle('light')
        } else if (keyCode === 90) {
          Z.toggle('dark')
          Z.toggle('light')
        } else if (keyCode === 88) {
          X.toggle('dark')
          X.toggle('light')
        } else if (keyCode === 67) {
          C.toggle('dark')
          C.toggle('light')
        } else if (keyCode === 86) {
          V.toggle('dark')
          V.toggle('light')
        } else if (keyCode === 66) {
          B.toggle('dark')
          B.toggle('light')
        } else if (keyCode === 78) {
          N.toggle('dark')
          N.toggle('light')
        } else if (keyCode === 77) {
          M.toggle('dark')
          M.toggle('light')
        }
      }, 100)
    }
  }
}
</script>
<style scoped>
.cat_container {
    width: 400px;
    margin: 0 auto;
    /* background: #fff; */
}

.cat {
    width: 400px;
    height: 200px;
    margin: 0 auto;
    /* background: #fff; */
    position: relative;
    cursor: pointer;
}

.catHead {
    width: 100px;
    height: 71px;
    background: transparent;
    position: absolute;
    top: 15px;
    left: 150px;
    border-top: 4px solid #222;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-radius: 35% 35% 10px 11px;
    transform: rotate(15deg);
    z-index: 1;
}

.catHead:before {
    content: "";
    display: block;
    width: 30px;
    height: 55px;
    background: transparent;
    position: absolute;
    top: 29px;
    left: 110px;
    border-right: 4px solid #000;
    border-radius: 0 0 50%/50px 20px;
    transform: rotate(-45deg);
}

.catHead:after {
    content: "";
    display: block;
    width: 30px;
    height: 55px;
    background: transparent;
    position: absolute;
    top: 9px;
    right: 95px;
    border-left: 4px solid #000;
    border-radius: 50%/50px 20px 0 0;
    transform: rotate(25deg);
}

/* left ear */
.catEars {
    width: 18px;
    height: 18px;
    /* background: #fff; */
    border-left: 3px solid #000;
    border-top: 4px solid #000;
    transform: rotate(35deg);
    position: absolute;
    left: 150px;
    top: 5px;
    border-radius: 15% 0 0 10%;
    z-index: 2;
}

.catEars:before {
    content: "";
    height: 23px;
    /* background: #fff; */
    border-left: 4px solid #000;
    transform: rotate(35deg);
    position: absolute;
    left: 98px;
    bottom: 43px;
    border-radius: 0 0 0 20%;
}

.rightEar {
    display: block;
    background: transparent;
    width: 2px;
    height: 15px;
    border-right: 4px solid #000;
    position: absolute;
    left: 106px;
    bottom: 50px;
    transform: rotate(-40deg);
    border-radius: 10% 10% 30% 10%;
}

.rightEar:before {
    content: "";
    width: 5px;
    height: 13px;
    border-right: 4px solid #000;
    transform: rotate(10deg);
    position: absolute;
    left: -4px;
    top: 13px;
    border-radius: 10% 10% 30% 10%;
}

.rightEar:after {
    content: "";
    width: 5px;
    height: 10px;
    border-right: 4px solid #000;
    transform: rotate(9deg);
    position: absolute;
    left: -6px;
    top: 25px;
    border-radius: 10% 10% 30% 10%;
}

/* eyes */
.face {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    left: 140px;
    top: 50px;
}

.face:before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    left: 60px;
    top: 15px;
}

.mouth {
    display: block;
    width: 10px;
    height: 8px;
    position: absolute;
    left: 15px;
    top: 0ps;
    transform: rotate(45deg);
    border-radius: 50%;
    border: 3px solid #000;
    border-left-color: transparent;
    border-top-color: transparent;
}

.mouth:before {
    content: "";
    display: block;
    width: 10px;
    height: 8px;
    position: absolute;
    left: 9px;
    top: -10px;
    transform: rotate(20deg);
    border-radius: 50%;
    border: 3px solid #000;
    border-left-color: transparent;
    border-top-color: transparent;
}

.leftPaw {
    width: 25px;
    height: 30px;
    /* background: #fff; */
    border: 4px solid #000;
    border-bottom: 0 solid transparent;
    border-right: 0 solid transparent;
    border-radius: 50% 40% 0 0;
    position: absolute;
    left: 90px;
    top: 40px;
    transform: rotate(-20deg);
    z-index: 2;
}

.leftPaw:before {
    content: "";
    display: block;
    width: 3px;
    height: 15px;
    background: #000;
    border-radius: 100% 0 0 0;
    position: absolute;
    left: 24px;
    top: 4px;
    transform: rotate(-20deg);
}

.rightPaw {
    width: 25px;
    height: 30px;
    /* background: #fff; */
    border: 3px solid #000;
    border-bottom: 0 solid transparent;
    border-right: 0 solid transparent;
    border-radius: 50% 40% 0 0;
    position: absolute;
    left: 222px;
    top: 60px;
    transform: rotate(-20deg);
    z-index: 2;
}

.rightPaw:before {
    content: "";
    display: block;
    width: 3px;
    height: 15px;
    background: #000;
    border-radius: 100% 0 0 0;
    position: absolute;
    left: 23px;
    top: 2px;
    transform: rotate(-23deg);
}

.surface {
    background-color: pink;
    width: 140px;
    height: 53px;
    border-bottom: 3px solid #000;
    transform: translateX(-73%) rotate(195deg) skewX(-10deg);
    position: absolute;
    top: 80px;
    left: 50%;
    z-index: 3;

    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.key-item {
    width: 12px;
    height: 12px;
    text-align: center;
    border: 0.5px solid black;
}

.dark {
    background-color: #fff;
}

.light {
    background-color: hsl(60, 100%, 70%);
}

.paws {
    display: block;
    width: 8px;
    height: 10px;
    background: #f19ba5;
    border-radius: 50% 50% 30% 30%;
    position: absolute;
    top: 9px;
    left: 7px;
}

.circles {
    display: block;
    width: 5px;
    height: 4px;
    background: #f19ba5;
    border-radius: 50% 50% 30% 30%;
    position: absolute;
    top: -3px;
    left: 8px;
}

.circles:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #f19ba5;
    border-radius: 50% 50% 30% 30%;
    position: absolute;
    top: -3px;
    left: -6px;
}

.circles:after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #f19ba5;
    border-radius: 50% 30%;
    position: absolute;
    top: 1px;
    left: -12px;
}

i {
    font-size: 24px;
    font-weight: 800;
    color: #abbac6;
    position: relative;
    left: 80px;
    padding: 10px 100px 10px 0;
}

.rotate {
    transform: scale(1, -1);
}

.red {
    color: red;
}

.green {
    color: limegreen;
}

.leftPaw2 {
    width: 40px;
    height: 28px;
    /* background: #fff; */
    border: 4px solid #000;
    border-bottom: 0 solid transparent;
    border-right: 0 solid transparent;
    border-radius: 50% 4px 50% 50%;
    position: absolute;
    left: 87px;
    top: 58px;
    transform: rotate(-43deg);
    z-index: 5;
}

.leftPaw2:before {
    content: "";
    display: block;
    width: 30px;
    height: 5px;
    /* background: #fff; */
    border-bottom: 4px solid #000;
    border-radius: 50% 4px 50% 50%;
    position: absolute;
    top: 20px;
    transform: rotate(20deg);
}

.rightPaw2 {
    width: 45px;
    height: 24px;
    /* background: #fff; */
    border-bottom: 3px solid #000;
    border-left: 3px solid #000;
    border-radius: 50% 4px 0 40%;
    position: absolute;
    left: 207px;
    top: 85px;
    transform: rotate(-23deg);
    z-index: 4;
}

.rightPaw2:before {
    content: "";
    display: block;
    width: 20px;
    height: 5px;
    /* background: #fff; */
    border-top: 3px solid #000;
    border-radius: 50% 4px 50% 50%;
    position: absolute;
    top: 0px;
    left: 2px;
    transform: rotate(-20deg);
}

.author {
    font-size: 10px;
    position: relative;
    top: -50px;
    left: 250px;
}

/* 捂眼睛 start */
.eyes_cover_left_hand {
    transform: scaleX(-1) skewX(30deg) translateX(-30px);
}
.eyes_cover_right_hand {
    transform: scaleX(1) skewX(20deg) translateX(-25px);
}
/* 捂眼睛 end */

[vs-theme='dark'] .leftPaw, .leftPaw2 {
  border: 4px solid #FFF ;
  border-bottom: 0 solid transparent ;
  border-right: 0 solid transparent ;
}
[vs-theme='dark'] .leftPaw:before, .leftPaw2:before {
  background: #FFF ;
}
[vs-theme='dark'] .catHead:before {
  border-right: 4px solid #FFF ;
}
[vs-theme='dark'] .catHead:after {
  border-left: 4px solid #FFF ;
}
[vs-theme='dark'] .catEars {
  border-left: 3px solid #FFF ;
  border-top: 4px solid #FFF ;
}
[vs-theme='dark'] .catEars:before {
  border-left: 4px solid #FFF ;
}
[vs-theme='dark'] .catHead {
  border-top: 4px solid #FFF ;
}
[vs-theme='dark'] .rightEar {
  border-right: 4px solid #FFF ;
}
[vs-theme='dark'] .rightEar:before {
  border-right: 4px solid #FFF ;
}
[vs-theme='dark'] .rightEar:after {
  border-right: 4px solid #FFF ;
}
[vs-theme='dark'] .rightPaw, .rightPaw2 {
  border: 3px solid #FFF ;
  border-bottom: 0 solid transparent ;
  border-right: 0 solid transparent ;
}
[vs-theme='dark'] .rightPaw:before, .rightPaw2:before {
  background: #FFF ;
}
[vs-theme='dark'] .face {
  background-color: #FFF;
}
[vs-theme='dark'] .face:before {
  background: #FFF;
}
[vs-theme='dark'] .mouth, .mouth:before {
  border: 3px solid #FFF ;
  border-left-color: transparent ;
  border-top-color: transparent ;
}
</style>
