<template>
    <div>
        <!-- 登录框 -->
        <vs-dialog prevent-close overflow-hidden v-model="loginDialogActive" class="dialog-login">
            <template #header>
                <h4 class="ldl-not-margin">
                    Welcome to <b>Login</b>
                </h4>
            </template>

            <div class="ldl-con-form">
                <vs-input v-model="username" placeholder="username" @keydown="focusUsername">
                    <template #icon>
                        @
                    </template>
                </vs-input>
                <vs-input type="password" v-model="password" placeholder="Password" @focus="focusPassword" @blur="blurPassword">
                    <template #icon>
                        <i class='bx bxs-lock'></i>
                    </template>
                </vs-input>
                <div class="ldl-flex">
                    <vs-checkbox v-model="disclaimers"><span @click="showDisclaimers">disclaimers</span></vs-checkbox>
                    <a href="#">Forgot Password?</a>
                </div>
            </div>

            <template #footer>
                <div class="ldl-footer-dialog">
                    <vs-button block @click="login">
                        Sign In
                    </vs-button>

                    <div class="ldl-new">
                        New Here? <a @click="showRegisterDialog">Create New Account</a>
                    </div>
                </div>
            </template>

            <KeyboardCat style="position: absolute; top: -190px; left: 0;" ref="cat"></KeyboardCat>
        </vs-dialog>

      <Disclaimers :disclaimersDialogActive="disclaimersDialogActive"></Disclaimers>
    </div>
</template>

<script>
import Disclaimers from '@/components/Disclaimers.vue'
import Crypto from 'crypto-js'
import KeyboardCat from '@/components/KeyboardCat.vue'
export default {
  name: 'Login',
  components: {
    Disclaimers,
    KeyboardCat
  },
  data () {
    return {
      loginDialogActive: false,
      disclaimersDialogActive: '',

      username: '',
      password: '',
      disclaimers: false
    }
  },
  methods: {
    blurPassword () {
      this.$refs.cat.blurPassword()
    },
    focusUsername () {
      this.$refs.cat.focusUsername()
    },
    focusPassword () {
      this.$refs.cat.focusPassword()
    },
    showDisclaimers () {
      this.disclaimersDialogActive = 'xxx' + (Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000)
    },
    login () {
      if (!this.disclaimers) {
        this.common.showNotification('warn', 'request denied', '请勾选声明')
        return
      }

      this.request.POST(
        '/user/login',
        JSON.stringify({
          username: this.username,
          password: Crypto.MD5(this.password).toString()
        })
      ).then(resp => {
        if (resp.code === 0) {
          window.sessionStorage.setItem('tokenStr', resp.data.tokenStr)

          this.loginDialogActive = false
          this.common.showNotification('success', 'request success', resp.message)

          this.common.eventBus().$emit('showAvatar')
        }
      })
    },
    showRegisterDialog () {
      this.loginDialogActive = false

      this.common.eventBus().$emit('openRegisterDialog')
    }
  },
  created () {
    // request.app
    this.common.eventBus().$on('openLoginDialog', () => {
      this.username = ''
      this.password = ''
      this.disclaimers = false

      this.loginDialogActive = true
      this.disclaimersDialogActive = ''
    })
  }
}
</script>

<style>
    .ldl-not-margin {
        margin: 0px;
        font-weight: normal;
        padding: 10px
    }

    .ldl-con-form{
        width: 100%
    }

    .ldl-flex {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
    a {
        font-size: .8rem;
        margin-left: 6px;
    }
    .vs-checkbox-label {
        font-size: .8rem
    }
    .vs-input-content{
        margin: 10px 0px;
        width: calc(100%)
    }
    .vs-input {
        width: 100% !important
    }
    .ldl-footer-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100%)
    }
    .ldl-new {
        margin: 0px;
        margin-top: 20px;
        padding: 0px;
        font-size: .7rem
    }
    .vs-button {
        margin: 0px
    }
  </style>
