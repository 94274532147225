/**
 * 统一封装请求接口
 */

import axios from 'axios'
import Vue from 'vue'
import Vuesax from 'vuesax'
import common from './common'

Vue.use(Vuesax)

// 设置请求超时时间
axios.defaults.timeout = 60000
// axios中请求配置有baseURL选项，表示请求URL公共部分
// axios.defaults.baseURL = process.env.SERVICE_URL
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 请求拦截器
axios.interceptors.request.use(config => {
  // 给请求头添加token
  const tokenStr = window.sessionStorage.getItem('tokenStr')
  if (tokenStr) {
    config.headers.Authorization = 'Bearer ' + tokenStr
  }

  return config
}, error => {
  throw new Error('请求拦截器配置出错' + error)
})

// 响应拦截器接口白名单: 接口使用了expressjwt解析用户失败,但是不需要提示用户登录
const responseInterceptorWhiteList = ['/api/comment/getCommentsByPostId']

// 响应拦截器
axios.interceptors.response.use(success => {
  if (responseInterceptorWhiteList.every(item => success.config.url.indexOf(item) !== -1)) {
    return success.data
  }

  // if (success.config.url.startsWith('http://localhost:81/api'))

  // 请求接口通了,http状态码200
  if (success.status && success.status === 200) {
    // 接受接口抛出的异常
    const errorCodes = [401, 500, 403, 1]
    if (errorCodes.includes(success.data.code)) {
      common.showNotification('danger', 'request error', success.data.message)

      if (success.data.code === 401) {
        setTimeout(() => {
          common.eventBus().$emit('openLoginDialog')
        }, 1000)
      }
    }

    return success.data
  }
}, error => {
  throw new Error('响应拦截器配置出错' + error)
})

const baseUrl = process.env.VUE_APP_BASE_URL
export default {
  GET: function (url, data, baseURL = baseUrl) {
    const loading = Vue.prototype.$vs.loading({
      type: 'square'
    })
    return new Promise((resolve, reject) => {
      axios.defaults.headers['ngrok-skip-browser-warning'] = 69420

      axios.get(baseURL + url, { params: data })
        .then(resp => {
          loading.close()
          resolve(resp)
        }, err => {
          loading.close()
          reject(err)
        })
    })
  },
  POST: function (url, data, contentType = 'application/json;charset=utf-8', baseURL = baseUrl) {
    const loading = Vue.prototype.$vs.loading({
      type: 'square'
    })
    return new Promise((resolve, reject) => {
      axios.defaults.headers['Content-Type'] = contentType
      axios.defaults.headers['ngrok-skip-browser-warning'] = 69420

      axios.post(baseURL + url, data)
        .then(resp => {
          loading.close()
          resolve(resp)
        }, err => {
          loading.close()
          reject(err)
        })
    })
  },
  PUT: function (url, data, baseURL = baseUrl) {
    const loading = Vue.prototype.$vs.loading({
      type: 'square'
    })
    return new Promise((resolve, reject) => {
      axios.put(baseURL + url, data)
        .then(resp => {
          loading.close()
          resolve(resp)
        }, err => {
          loading.close()
          reject(err)
        })
    })
  }
}
