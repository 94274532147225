import Vue from 'vue'
import Vuex from 'vuex'
import request from '../utils/request'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAdminAuthenticatedFlag: false,

    targetUrl: '', // 第三方链接
    targetUrlDescription: '' // 第三方链接描述
  },
  mutations: {
    // 定义setAdminStatus mutation
    setAdminAuthenticatedFlag (state, flag) {
      // 直接修改state中的isAdmin状态
      state.isAdminAuthenticatedFlag = flag
    },

    setTargetUrl (state, targetUrl) {
      state.targetUrl = targetUrl
    },
    setTargetUrlDescription (state, targetUrlDescription) {
      state.targetUrlDescription = targetUrlDescription
    }
  },
  actions: {
    async checkAdmin ({ commit }) {
      try {
        const response = await request.GET('/user/checkAdmin', {})
        commit('setAdminAuthenticatedFlag', response.data)
      } catch (error) {
        commit('setAdminAuthenticatedFlag', false)
      }
    }
  },
  getters: {
    isAdminAuthenticatedFlag: state => state.isAdminAuthenticatedFlag,
    targetUrl: state => state.targetUrl,
    targetUrlDescription: state => state.targetUrlDescription
  }
})
