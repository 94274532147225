import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'boxicons'
import 'boxicons/css/boxicons.min.css'

// 自定义请求工具类,公共类
import request from './utils/request.js'
import common from './utils/common.js'

import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import Prism from 'prismjs'
// highlightjs 核心代码
import Hljs from 'highlight.js'

import echarts from 'echarts'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

// swiper
/* 引入swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import animate from 'animate.css'

VueMarkdownEditor.use(githubTheme, {
  Prism,
  Hljs
})
Vue.use(VueMarkdownEditor)

console.log(`
____   ____           __________.__                                   __________.__
\\   \\ /   /_ __   ____\\______   \\  |   ____   ____   ____   __________\\______   \\  |  __ __  ______
\\   Y   /  |  \\_/ __ \\|    |  _/  |  /  _ \\ / ___\\ / ___\\_/ __ \\_  __ \\     ___/  | |  |  \\/  ___/
\\     /|  |  /\\  ___/|    |   \\  |_(  <_> ) /_/  > /_/  >  ___/|  | \\/    |   |  |_|  |  /\\___ \\
\\___/ |____/  \\___  >______  /____/\\____/\\___  /\\___  / \\___  >__|  |____|   |____/____//____  >
\\/       \\/           /_____//_____/      \\/                               \\/
`)

Vue.config.productionTip = false

Vue.use(Vuesax, {})
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
Vue.use(Viewer)
Vue.use(VueAwesomeSwiper)
Vue.use(animate)

Vue.prototype.request = request
Vue.prototype.common = common
Vue.prototype.echarts = echarts

Vue.mixin({
  methods: {
    changeTitle: function (title) {
      let Config = null
      try {
        Config = require('@/../posts/data/config.json')
      } catch (e) {
        Config = require('@/defaults/config.json')
      }
      document.title = `${title} - ${Config.blogTitle}`
    },
    getConfig: function (name = 'config.json') {
      let Config = null
      try {
        Config = require(`@/../posts/data/${name}`)
      } catch (e) {
        Config = require(`@/defaults/${name}`)
      }
      return Config
    }
  }
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
