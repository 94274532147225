<template>
    <div>
        <div class="music_card_container">
            <div class="music_card">
                <div class="music_main_content">
                    <span class="music_title">Music</span>
                    <div class="music_icon">
                        <svg viewBox="0 0 16 16" class="note bi bi-music-note" fill="currentColor" height="18" width="18"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z"></path>
                            <path d="M9 3v10H8V3h1z" fill-rule="evenodd"></path>
                            <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z"></path>
                        </svg>
                    </div>
                    <span class="music_desp">
                        <div></div>
                        music_main_content piece first ending
                    </span>
                    <div class="music_bar">
                        <svg viewBox="0 0 16 16" class="music_pre_btn color bi bi-fast-forward-fill" fill="currentColor" height="16"
                            width="16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z">
                            </path>
                            <path
                                d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z">
                            </path>
                        </svg>
                        <svg @click="playOrPause" v-if="!playMusicFlag_" viewBox="0 0 16 16" class="music_play_btn color bi bi-caret-right-fill" fill="currentColor" height="18"
                            width="18" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z">
                            </path>
                        </svg>
                        <svg @click="playOrPause" v-if="playMusicFlag_" t="1709889229162" class="music_play_btn icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6041" width="16" height="16"><path d="M686.480358 119.560026c0-32.377408 26.325575-58.644655 58.644655-58.644655 32.386618 0 58.645678 26.420743 58.645678 58.644655l0 781.930779c0 32.376385-26.325575 58.644655-58.645678 58.644655-32.385595 0-58.644655-26.419719-58.644655-58.644655L686.480358 119.560026zM217.321072 119.560026c0-32.377408 26.325575-58.644655 58.645678-58.644655 32.385595 0 58.644655 26.420743 58.644655 58.644655l0 781.930779c0 32.376385-26.325575 58.644655-58.644655 58.644655-32.385595 0-58.645678-26.419719-58.645678-58.644655L217.321072 119.560026z" fill="#7D7D7D" p-id="6042"></path></svg>
                        <svg viewBox="0 0 16 16" class="music_next_btn color bi bi-fast-forward-fill" fill="currentColor" height="16"
                            width="16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z">
                            </path>
                            <path
                                d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div class="two"></div>
                <div class="three"></div>

                <!-- 关闭按钮 -->
                <div class="music_close_btn" @click="closeMusicPanel">
                    <svg t="1709870580595" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2827" width="32" height="32">
                        <path
                            d="M913.15 260.51a480.53 480.53 0 0 0-203.8-174 40 40 0 0 0-32.92 72.91C819.53 224.06 912 367.23 912 524.19c0 220.56-179.44 400-400 400s-400-179.44-400-400c0-157 92.47-300.14 235.57-364.75a40 40 0 0 0-32.92-72.91 480 480 0 1 0 598.5 174z"
                            fill="#FF8429" p-id="2828"></path>
                        <path
                            d="M172.06 524.19a15 15 0 1 0-30 0 369.94 369.94 0 1 0 739.88 0 369 369 0 0 0-120.59-273.27 15 15 0 1 0-20.23 22.16 340.79 340.79 0 0 1 110.82 251.11c0 187.44-152.5 339.94-339.94 339.94S172.06 711.64 172.06 524.19z"
                            fill="#FF8429" p-id="2829"></path>
                        <path d="M692.89 218.75m-15 0a15 15 0 1 0 30 0 15 15 0 1 0-30 0Z" fill="#FF8429" p-id="2830"></path>
                        <path d="M512 595.26a40 40 0 0 0 40-40V59.81a40 40 0 0 0-80 0v495.45a40 40 0 0 0 40 40z"
                            fill="#00CEDD" p-id="2831"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: ['playMusicFlag', 'audio', 'musicUrl'],
  data () {
    return {
      playMusicFlag_: this.playMusicFlag,
      audio_: this.audio,
      musicUrl_: this.musicUrl
    }
  },
  methods: {
    playOrPause () {
      this.playMusicFlag_ = !this.playMusicFlag_

      var musicPanelData = {}
      musicPanelData.playMusicFlag = this.playMusicFlag_

      if (this.audio_ === null) {
        this.request
          .GETTEST('https://api.uomg.com/api/rand.music', {
            sort: '热歌榜',
            format: 'json'
          })
          .then((res) => {
            if (this.playMusicFlag_) {
              var newAudio = new Audio(res.data.url)
              newAudio.loop = true
              this.audio_ = newAudio
              this.musicUrl_ = res.data.url

              newAudio.play()

              musicPanelData.musicUrl = res.data.url
              musicPanelData.audio = newAudio
              this.$emit('getMusicPanelData', musicPanelData)
            } else {
              this.audio_.pause()

              this.$emit('getMusicPanelData', musicPanelData)
            }
          })
      } else {
        if (this.playMusicFlag_) {
          this.audio_.play()

          this.$emit('getMusicPanelData', musicPanelData)
        } else {
          this.audio_.pause()

          this.$emit('getMusicPanelData', musicPanelData)
        }
      }
    },
    closeMusicPanel () {
      this.$emit('closeMusicPanel')

      var musicPanelData = {}
      musicPanelData.playMusicFlag = this.playMusicFlag_
      musicPanelData.audio = this.audio_
      musicPanelData.musicUrl = this.musicUrl_
      this.$emit('getMusicPanelData', musicPanelData)
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
.music_card_container {
    background-color: rgba(0, 0, 0, var(--vs-background-opacity));
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10000;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: saturate(180%) blur(15px);
}

.music_card {
    width: 190px;
    height: 254px;
    background: lightgrey;
    border-radius: 10px;
    margin: auto;
    position: relative;
}

.music_card .music_main_content {
    width: 190px;
    height: 254px;
    z-index: 10;
    position: absolute;
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.music_card .music_main_content .music_title {
    width: 70px;
    border: 1px solid rgba(180, 177, 177, 0.308);
    display: block;
    margin: 12px auto;
    text-align: center;
    font-size: 10px;
    border-radius: 12px;
    font-family: Roboto, sans-serif;
    color: rgba(102, 100, 100, 0.911);
}

.music_card .music_main_content .music_icon {
    width: 80px;
    height: 80px;
    background: rgba(216, 212, 212, 0.726);
    margin: 30px auto;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.music_card .music_main_content .music_desp {
    width: 150px;
    height: 50px;
    font-size: 12px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    padding: 0 5px;
    margin: -22px auto;
    display: block;
    overflow: hidden;
    text-align: center;
    color: rgba(50, 49, 51, 0.637);
}

.music_card .music_main_content .music_bar {
    width: 100px;
    margin: 25px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    cursor: pointer;
}

.music_card .music_main_content .music_bar .color {
    fill: rgba(82, 79, 79, 0.829);
}

.music_card .music_main_content .music_bar .color1 {
    fill: rgba(29, 28, 28, 0.829);
    cursor: pointer;
}

.music_card .two {
    width: 60px;
    height: 60px;
    background-color: rgb(131, 25, 163);
    filter: drop-shadow(0 0 10px rgb(131, 25, 163));
    border-radius: 50%;
    position: relative;
    top: 30px;
    left: 20px;
    animation: music_main_content 5s infinite;
}

.music_card .three {
    width: 60px;
    height: 60px;
    background-color: rgb(29, 209, 149);
    filter: drop-shadow(0 0 10px rgb(29, 209, 149));
    border-radius: 50%;
    position: relative;
    top: 90px;
    left: 90px;
    animation: two 5s infinite;
}

.music_pre_btn,
.music_play_btn,
.music_next_btn {
    transition: transform .2s;
}

.music_pre_btn:hover,
.music_play_btn:hover,
.music_next_btn:hover {
    transform: scale(1.5);
}

.music_close_btn {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 11;
    width: 25px;
    height: 25px;
    transition: transform .2s;
}

.music_close_btn:hover {
    transform: scale(1.5);
}

.music_close_btn svg {
    width: 100%;
    height: 100%;
}

@keyframes music_main_content {
    0% {
        top: 30px;
        left: 20px;
    }

    20% {
        top: 50px;
        left: 40px;
    }

    40% {
        top: 80px;
        left: 70px;
    }

    50% {
        top: 60px;
        left: 40px;
    }

    60% {
        top: 35px;
        left: 90px;
    }

    80% {
        top: 70px;
        left: 70px;
    }

    100% {
        top: 30px;
        left: 20px;
    }
}

@keyframes two {
    0% {
        top: 90px;
        left: 90px;
    }

    20% {
        top: 50px;
        left: 40px;
    }

    40% {
        top: 60px;
        left: 20px;
    }

    50% {
        top: 80px;
        left: 30px;
    }

    60% {
        top: 35px;
        left: 90px;
    }

    80% {
        top: 70px;
        left: 60px;
    }

    100% {
        top: 90px;
        left: 90px;
    }
}
</style>
