import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import common from '../utils/common'

// import Home from '../views/Home.vue'
// import ViewPost from '../views/ViewPost.vue'
// import ViewTag from '../views/ViewTag.vue'
// import ViewGenre from '../views/ViewGenre.vue'
// import ViewSeries from '../views/ViewSeries.vue'
// import About from '../views/About.vue'
// import ViewGenresTags from '../views/ViewGenresTags.vue'
// import ViewProjects from '../views/ViewProjects.vue'
// import Links from '../views/Links.vue'
// import ViewPosts from '../views/ViewPosts.vue'
// import NotFound from '../views/errors/NotFound.vue'
// import Edit from '../views/Edit.vue'
// import JumpConfirm from '../views/JumpConfirm.vue'

const children = []

let Posts = null
try {
  Posts = require('@/../posts/data/posts.json')
} catch (e) {
  Posts = require('@/defaults/posts.json')
}

Posts.posts.map(post => {
  children.push({
    path: post.id,
    component: async function () {
      let value
      await import(`@/../posts/${post.id}.md`).then((val) => {
        value = val
      })
      return value.vue.component
    }
  })
})
Vue.use(VueRouter)

const routes = [
  {
    path: '/tiktok',
    name: 'TiktokPage',
    meta: {
      isExternalLink: true, // 标记这个路由是外部链接
      targetUrl: 'https://www.douyin.com/user/MS4wLjABAAAAqB2iN0fqj_AwwYlw7rFa5hPHe29HRBwCvCWSCb5STb4LjgBKRapEY5qbTFHRMzGl',
      description: '站长的抖音主页'
    }
  },
  {
    path: '/chatGpt',
    name: 'ChatGptage',
    meta: {
      isExternalLink: true, // 标记这个路由是外部链接
      targetUrl: 'https://gpt.qnmdmyy.top/',
      description: '站长搭建的chatGPT'
    }
  },
  {
    path: '/gitee',
    name: 'GiteePage',
    meta: {
      isExternalLink: true, // 标记这个路由是外部链接
      targetUrl: 'https://gitee.com/sunny_bigboy',
      description: '站长的gitee主页'
    }
  },
  {
    path: '/github',
    name: 'GithubPage',
    meta: {
      isExternalLink: true, // 标记这个路由是外部链接
      targetUrl: 'https://github.com/RecklessZhang',
      description: '站长的github主页'
    }
  },
  {
    path: '/jump',
    name: 'JumpConfirm',
    // component: JumpConfirm,
    component: () => import('../views/JumpConfirm.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true,
      hideFlexibleBall: true
    }
  },
  {
    path: '/',
    name: 'Home',
    // component: Home
    component: () => import('../views/Home.vue')
  },
  {
    path: '/posts',
    name: 'ViewPosts',
    // component: ViewPosts,
    component: () => import('../views/ViewPosts.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/post/:postId',
    name: 'ViewPost',
    // component: ViewPost,
    component: () => import('../views/ViewPost.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/tag/:tagName',
    name: 'ViewTag',
    // component: ViewTag,
    component: () => import('../views/ViewTag'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/genre/:genreName',
    name: 'ViewGenre',
    // component: ViewGenre,
    component: () => import('../views/ViewGenre.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/series/:genreName',
    name: 'ViewSeries',
    // component: ViewSeries,
    component: () => import('../views/ViewSeries.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // component: About,
    component: () => import('../views/About.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/gt',
    name: 'ViewGenresTags',
    // component: ViewGenresTags,
    component: () => import('../views/ViewGenresTags.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/projects',
    name: 'ViewProjects',
    // component: ViewProjects,
    component: () => import('../views/ViewProjects.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/links',
    name: 'Links',
    // component: Links,
    component: () => import('../views/Links.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '/edit/add',
    name: 'Edit',
    // component: Edit,
    component: () => import('../views/Edit.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true,
      needAdminAuth: true
    }
  },
  {
    path: '/edit/post/:postId',
    name: 'EditPost',
    // component: Edit,
    component: () => import('../views/Edit.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true,
      needAdminAuth: true
    }
  },
  {
    path: '/edit/draft/:draftId',
    name: 'EditDraft',
    // component: Edit,
    component: () => import('../views/Edit.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true,
      needAdminAuth: true
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    // component: NotFound,
    component: () => import('../views/errors/NotFound.vue'),
    meta: {
      hideFooter: true
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 需要管理员权限的路由
  if (to.meta.needAdminAuth) {
    // /edit如果是移动端登录,也不用验证用户了,直接跳转就行
    if (to.path === '/edit/add' && common.getDeviceType() !== 'pc') {
      common.showNotification('warn', 'warning', '请在PC端访问页面')
      next('/')
    } else {
      // 获取用户权限
      store.dispatch('checkAdmin').then(() => {
        // 拥有管理员权限
        if (store.getters.isAdminAuthenticatedFlag) {
          next()
        } else {
          next('/404')
        }
      })
    }
  } else {
    if (to.meta.isExternalLink) {
      const targetUrl = to.meta.targetUrl
      const targetUrlDescription = to.meta.description
      store.commit('setTargetUrl', targetUrl)
      store.commit('setTargetUrlDescription', targetUrlDescription)
      next('/jump')
    } else {
      next()
    }
  }
})

export default router
