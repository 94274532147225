<template>
    <div>
        <vs-dialog scroll prevent-close overflow-hidden auto-width v-model="localDisclaimersDialogActive">
            <template #header>
                <h3>
                    Disclaimers
                </h3>
            </template>
            <div class="con-content">
                <h4>
                    尊敬的用户：
                </h4>
                <p>
                    感谢您参与我们博客的评论。在您发表评论之前，请您务必遵守以下准则：尊重他人、遵守法律法规、不得发布侮辱、恶意攻击、色情、暴力或其他违反社会公德的内容。请确保您的评论是真实、客观、合法的，因为您的每一条评论都代表着您自己。
                </p>
                <p>
                    请注意，本站将不对用户发表的评论内容负责。用户所发表的评论，均代表个人观点，与本站立场无关。任何违反法律、道德或侵权他人权益的评论，都将由用户自行承担法律责任。
                </p>
                <p>
                    我们希望您能够理解并遵守上述准则，共同维护一个良好的评论环境，为博客的发展贡献力量。谢谢！
                </p>

                <h4>
                    Dear user,
                </h4>

                <p>
                    Thank you for participating in the comments section of our blog. Before posting a comment, please be
                    sure to adhere to the following guidelines: Respect others, comply with laws and regulations, and
                    refrain from posting content that is insulting, malicious, pornographic, violent, or otherwise violates
                    public morality. Please ensure that your comments are truthful, objective, and legal, as each of your
                    comments represents you.
                </p>
                <p>
                    Please note that our site will not be responsible for the content of user comments. Comments posted by
                    users represent personal opinions and are not endorsed by our site. Any comments that violate laws,
                    ethics, or infringe upon the rights of others will be the sole responsibility of the user.
                </p>
                <p>
                    We hope that you understand and adhere to the above guidelines, working together to maintain a positive
                    commenting environment and contribute to the development of the blog. Thank you!
                </p>
            </div>
        </vs-dialog>
    </div>
</template>

<script>

export default {
  name: 'Disclaimers',
  props: {
    disclaimersDialogActive: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      localDisclaimersDialogActive: this.disclaimersDialogActive.length > 0
    }
  },
  methods: {
  },
  created () {
  },
  watch: {
    disclaimersDialogActive: {
      immediate: true,
      handler (newVal) {
        if (newVal.length > 0) {
          this.localDisclaimersDialogActive = true
        }
      }
    }
  }
}
</script>

<style></style>
