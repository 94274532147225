<template>
  <div>
    <vs-navbar shadow-scroll fixed center-collapsed not-line style="z-index: 2000;">
      <template #left>
        <vs-button flat icon style="margin-right: 20px" class="menu-btn" @click="activeSidebar = !activeSidebar">
          <i class='bx bx-menu'></i>
        </vs-button>
        <router-link to="/" class="navbar-link">
          <h3>{{ config.blogTitle }}</h3>
        </router-link>
      </template>
      <vs-navbar-item
        v-for="(link, index) in links"
        :key="index"
        :active="active.name === link.name"
        :to="active.name === link.name ? null : link.url"
      >
        {{ link.name }}
      </vs-navbar-item>

      <router-link :to="`/terminal`" v-show="showAvatar">
        <!-- <MessageBtnNav :messageCount="messageCount"></MessageBtnNav> -->
        <vs-avatar badge badge-color="danger" size="35" style="margin-left: 25px;">
<!--          <img src="https://vuesax.com/avatars/avatar-2.png" alt="">-->
          <template #badge>
            {{ messageCount }}
          </template>
        </vs-avatar>
      </router-link>

      <template #right>
        <vs-navbar-item>
          <vs-switch
            v-model="dark"
          >
            <template #circle>
              <i v-if="!dark" class='bx bxs-sun' ></i>
              <i v-else class='bx bxs-moon' ></i>
            </template>
          </vs-switch>
        </vs-navbar-item>
      </template>
    </vs-navbar>
      <vs-sidebar
        v-model="activeItem"
        :open.sync="activeSidebar"
      >
        <template #logo>
          <h3>{{ config.blogTitle }}</h3>
        </template>
        <vs-sidebar-item
          v-for="(link, index) in links"
          :key="index"
          :to="link.url"
          :id="link.name"
        >
          {{ link.name }}
        </vs-sidebar-item>
        <div style="margin: 0 auto;">
          <router-link :to="`/terminal`">
            <MessageBtnSidebar :messageCount="messageCount"></MessageBtnSidebar>
          </router-link>
        </div>
      </vs-sidebar>
    <div style="margin-bottom: 70px;"></div>
  </div>
</template>

<script>
// import MessageBtnNav from '@/components/MessageBtnNav.vue'
import MessageBtnSidebar from '@/components/MessageBtnSidebar.vue'
export default {
  name: 'Navbar',
  components: {
    // MessageBtnNav,
    MessageBtnSidebar
  },
  watch: {
    dark (newValue) {
      if (!newValue) {
        this.dark = false
        document.getElementsByTagName('body')[0].setAttribute('vs-theme', 'light')
        window.localStorage.setItem('vs-theme', 'light')
        // 通知about页面修改iframe的主题
        this.common.eventBus().$emit('changeTheme', { theme: 'light' })
      } else {
        this.dark = true
        document.getElementsByTagName('body')[0].setAttribute('vs-theme', 'dark')
        window.localStorage.setItem('vs-theme', 'dark')
        // 通知about页面修改iframe的主题
        this.common.eventBus().$emit('changeTheme', { theme: 'dark' })
      }
    }
  },
  data: () => {
    return {
      config: null,
      activeItem: 'Home',
      activeSidebar: false,
      dark: false,
      links: [
        {
          name: 'Home',
          url: '/'
        },
        {
          name: 'Posts',
          url: '/posts'
        },
        {
          name: 'G&&T',
          url: '/gt'
        },
        {
          name: 'Projects',
          url: '/projects'
        },
        {
          name: 'Links',
          url: '/links'
        },
        {
          name: 'About',
          url: '/about'
        }
      ],

      messageCount: '99+',
      showAvatar: false
    }
  },
  methods: {
  },
  computed: {
    active: function () {
      const cur = this.$route.path
      var ret = {
        name: null,
        url: null
      }
      for (var i = 0; i < this.links.length; i++) {
        if (this.links[i].url === cur) ret = this.links[i]
      }
      return ret
    }
  },
  created () {
    this.config = this.getConfig()

    // 初始化theme为light,方便其他组件比如评论组件在css中根据vs-theme的值动态给字体颜色赋值
    if (window.localStorage.getItem('vs-theme')) {
      document.getElementsByTagName('body')[0].setAttribute('vs-theme', window.localStorage.getItem('vs-theme'))
      this.dark = window.localStorage.getItem('vs-theme') === 'dark'
    } else {
      document.getElementsByTagName('body')[0].setAttribute('vs-theme', 'light')
      window.localStorage.setItem('vs-theme', 'light')
      this.dark = false
    }

    this.common.eventBus().$on('showAvatar', () => {
      this.showAvatar = true
    })
  }
}
</script>

<style scoped>
.vs-switch {
  background-color: darkgray;
}
</style>
